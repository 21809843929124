import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Box, Button, Container, Input, Link } from '@mui/material'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'

import { SubmitButton } from '@/components/common/SubmitButton'
import { axiosContext } from '@/context/axios'

const App = () => {
  const { axiosInstance } = useContext(axiosContext)
  const router = useRouter()

  const [loginFormValues, setLoginFormValues] = useState<loginFormValues>({
    email: '',
    password: '',
  })

  const handleInputChange = (name: string, value: string) => {
    setLoginFormValues((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    router.replace('/register')
  }

  const login = async () => {
    try {
      await axiosInstance.get('/sanctum/csrf-cookie')
      await axiosInstance.post('/api/login', {
        email: loginFormValues.email,
        password: loginFormValues.password,
      })
      router.replace('/')
    } catch (error) {
      toast.error('ログインに失敗しました。')
    }
  }

  return (
    <Container
      sx={{
        margin: 0,
        position: 'absolute',
        top: '42%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        justifyContent: 'center',
      }}
    >
      <Box textAlign="center">
        <Image src="/images/logo_horizontal.png" alt="logo" width="300" height="200" objectFit="contain" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Input
          fullWidth
          type="text"
          name="email"
          placeholder="メールアドレス"
          value={loginFormValues.email}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
        />
      </Box>
      <Box sx={{ mt: '30px' }}>
        <Input
          fullWidth
          type="password"
          name="password"
          placeholder="パスワード"
          value={loginFormValues.password}
          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
        />
      </Box>
      <Box textAlign="center" sx={{ mt: '30px', mb: '50px' }}>
        パスワードを忘れた方は<Link href="/forgotPassword">こちら</Link>
      </Box>
      <Box textAlign="center">
        <SubmitButton onClick={login}>ログイン</SubmitButton>
      </Box>
      <Box textAlign="center" sx={{ mt: '30px' }}>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          <PersonAddIcon />
          新規登録
        </Button>
      </Box>
    </Container>
  )
}

App.requireAuth = false

export default App

type loginFormValues = {
  [key in 'email' | 'password']: string
}
