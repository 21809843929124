import { LoadingButton } from '@mui/lab'
import React, { useState } from 'react'

export const SubmitButton: React.FC<{
  onClick: () => void
}> = ({ onClick, children }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleClick = async () => {
    setLoading(true)
    await onClick()
    setLoading(false)
  }

  return (
    <LoadingButton loading={loading} variant="contained" color="primary" onClick={handleClick}>
      {children}
    </LoadingButton>
  )
}
